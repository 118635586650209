import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from "prop-types"
import { Layout } from 'antd';
import 'font-awesome/less/font-awesome.less';
import style from './header.module.less';
import '../../../styles/global.less';
import { useWindowSize } from '../../../utils/hooks'

const userbase = typeof window !== 'undefined' ? require('userbase-js').default : null;

const Header = ({ user }) => {
  const [menu, setMenu] = useState(false);
  const [width] = useWindowSize();
  const toggleMenu = () => {
    if (width !== 0 && width <= 768) {
      if (menu) {
        setMenu(false);
      } else {
        setMenu(true);
      }
    }
  }

  console.log(user)

  const signOut = () => {
    userbase.signOut()
    .then(() => {
      navigate('/app/login')
    })
    .catch((e) => console.error(e))
  }

  return (
    <>
      <div className={style.circleMenu} role="button" tabIndex="0" onKeyDown={toggleMenu} onClick={toggleMenu}>
        <div className={`${style.hamburger} ${menu ? style.menuIcon : null}`}>
          <div className={style.line} />
          <div className={style.line} />
          <div className={style.hamburgerText}>MENU</div>
        </div>
      </div>
      <Layout className={`${style.navWrap} ${menu ? null : style.hidden} ${menu ? style.openMenu : null}`}>
        <div className={style.backgroundDiv}>
          <ul className={style.nav}>
          <li className={style.navItem}>
              <Link to="/" onClick={toggleMenu} activeClassName={style.anchorActive}>
                Home
              </Link>
            </li>
            <li className={style.navItem}>
              <Link to="/science" onClick={toggleMenu} activeClassName={style.anchorActive}>
                Science
              </Link>
            </li>
            <li className={style.navItem}>
              <Link to="/math" onClick={toggleMenu} activeClassName={style.anchorActive}>
                Math
              </Link>
            </li>
            <li className={style.navItem}>
              <Link to="/english" onClick={toggleMenu} activeClassName={style.anchorActive}>
                English
              </Link>
            </li>
            <li className={style.navItem}>
              <Link to="/contact" onClick={toggleMenu} activeClassName={style.anchorActive}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </Layout>
    </>
  )
}

export default Header

            // <li className={style.navItem}>
            //   <Link onClick={signOut} activeClassName={style.anchorActive}>
            //     Logout
            //   </Link>
            // </li>

Header.propTypes = {
  user: PropTypes.object,
}

Header.defaultProps = {
  user: null
}